import AppContent from './appcontent.jsx'

function App() {
  return (
    <div>
      <AppContent/>
    </div>
  );
}

export default App;
